body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #06133b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.siteHeader{
}

.rmc-picker{
  width: 300px !important;
  position: absolute !important;
  right: 20px !important;
  top:-40px !important;
}

.noSelect{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.rmc-picker-mask{
  background-image: linear-gradient(to bottom, rgba(6,19,59,1), rgba(6,19,59,0.6)), linear-gradient(to top, rgba(6,19,59,1), rgba(6,19,59,0.6)) !important;
}


#dslogo {
  height: 6vmin;
  pointer-events: none;
  vertical-align: middle;
}

#logobox{
  position: absolute;
  top:30px;
  left:30px;
  width:95%
}

.logout{
  display: inline-block;
  vertical-align: middle;
  color:#00FFFF;
  font-weight: bold;
  margin-left: 20px;
  font-family: Raleway;
}

.visualiserButtom{
  display: inline-block;
  vertical-align: middle;
  color:#00FFFF;
  font-weight: bold;
  margin-left: 20px;
  font-family: Raleway;
}

.toggleFS{
  position: absolute;
  right:50px;
  bottom:40px;
  color: white;
}

.login_primary{
  font-size: 24px;
  color:#00FFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border:1px solid #00FFFF;
  border-radius: 10px;
}

#vesselName_top{
  display: inline-block;
  vertical-align: middle;
  color:#00FFFF;
  font-weight: bold;
  font-size: 24px;
  margin-left: 20px;
  font-family: Raleway;
}

.selectModelWrapper{
  position: absolute !important;
  right: 20px !important;
  top:10px !important;
  display: inline-block;
}

.selectModelLeftButton{
  border: 1px solid #00FFFF;
  padding:10px;
  border-top-left-radius:10px;
  border-bottom-left-radius:10px;
  display: inline-block;
  cursor: pointer;
  width: 140px;
  text-align: center;
}

.selectModelRightButton{
  border: 1px solid #00FFFF;
  border-left: none;
  padding:10px;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  display: inline-block;
  cursor: pointer;
  width: 140px;
  text-align: center;
}

button:focus {
      outline: none;
    }
