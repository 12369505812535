@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

.App {
  text-align: center;
  font-family: Raleway;
  background-color: #06133b;
}

.button-bar{
  position: absolute;
  bottom:90px;
  right:40px;
}

#optimise{
  width:80%;
}

#vsrouting{
  width:80%;
  margin-right: 20px;
}

#toggleLanguage{
  position: absolute;
  bottom: 30px;
  right:30px;
}

#toggleFouling{
  position: absolute;
  bottom: 100px;
  right:30px;
  border: 1px solid white;
  border-radius: 8px;
  padding:12px;
  font-size: 20px;
  font-weight: bold;
  color: #0e1e31;
  background-color: white
}

button{
  padding:10px;
  font-size: 14px;
  background-color: transparent;
  border:none;
  border-radius: 8px;
  color:white;
  font-family: Raleway;
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader{
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: -1;
  margin: auto;
  top: 40%;
  animation: spinner 1.5s linear infinite;
}

.loader img{
  height:100%
}

.info-bar{
  position: absolute;
  bottom:20px;
  left:40px;
}

.info-bar p {
  color: white;
  font-size: 15px;
  text-align: left
}

.problemButton{
  position: absolute;
  bottom:20px;
  right:40px;
  font-size: 18px
}

.App-header {
  background-color: #06133b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.greenText{
  color:#00FFFF;
  font-weight: bold;
  font-size: 18px;
}

.blueText{
  color:#06133b;
  font-weight: bold;
  font-size: 18px;
}

.speedToggler{
  background-color: #00FFFF;
  padding: 5px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
}

#map{
  width:70%;
  margin-top: 50px
}
